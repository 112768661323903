/**
* reports.css
*
* Contiene los estilos del generador de informes
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

.reportTbar {
    overflow: hidden;
    display: table;    
    width: 100%;
    height: 60px !important;
    margin: 0;
    padding: 0 10px 0 10px;
}

.reportTbar div, .reportTbar button {
    color: #fff;
}

.reportTbar > div {
    display: table-cell;
}

.reportBtn {
    padding: 2px;
    vertical-align: top;
    background-color: rgba(0,0,0,0);
    min-height: 50px;
    min-width: 84px;
    cursor: pointer;
    background-color: #2a80b9 !important;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.reportBtn button {
    width: 100%;
    background-color: rgba(0,0,0,0);
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: center top;
    min-height: 28px;
    min-width: 40px;
    cursor: pointer;
    padding: 26px 0 0 0;
    font-size: 11px;
    border: 1px solid transparent;
    text-decoration: none;
    outline: none;
}

.reportBtn button {
    padding: 0;
}

.ContentReport {
    width: 290px;
    height: 60px;
    float: right;
    padding: 0 10px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.reportContainer {
    width: 900px;
    height: calc(100% - 60px) !important; 
    background-color: #ccc;
}

.tbarReportContainer {
    width: 100%;
    background: linear-gradient(to bottom,#9c9c9c 0%,#ddd 2%,#ddd 100%);
    padding: 15px 5px 15px 5px;
    height:104px;
    overflow: visible;
}

.panelFieldReport {
    float: left;
    width: 420px;
    padding: 7px 0 0 0;
}

.formContentField {
    float: left;
    margin: 0 0 2px 10px;
}

.panelFieldReport > .formContentField > .formLabelReport {
    float: left;
    width: 70px !important;
    font-size: 11px;
}

.formFieldReport {
    float: left;    
}

.formDateReport {
    float: left;  
    position: absolute;
    background-color: white;      
}

.panelFieldReport > .formContentField > .formFieldReport > input, .panelFieldReport > .formContentField > .formFieldReport > select, .panelFieldReport > .formContentField > .formFieldReport > textarea {
    float: left;
    height: 23px;
    width: 130px !important;
    box-shadow: 1px 1px 3px 0 #aaa;
    background-color: #fff;
    font-size: 12px;
}

input[data-required="true"], select[data-required="true"], textarea[data-required="true"] {
    border-left: 2px solid #ff7676;
}

.PanelBotonesReport {
    display: flex;
    width: 240px;
    padding: 0 15px 0 15px;
}

.PanelBotonesReport > .reportBtn {
    float: left;
}

.reportBtn {
    color: #fff;
    padding: 2px;
    vertical-align: top;
    background-color: rgba(0,0,0,0);
    min-height: 50px;
    min-width: 84px;
    cursor: pointer;
}

.Select-menu-outer {
z-index: 1000;
}

.btnReportContainer {
    background-color: rgba(0,0,0,0) !important;
}

.PanelReport {
    width: 100%;
    background-color: #ddd;
    overflow: auto;
}

.ReportContainer {
    /*width: calc(100% - 30px);*/
    min-height: calc(100% - 30px);
    height: auto;
    background-color: #fff;
    margin: 15px;
    padding: 15px;
    float: left;
    box-shadow: 2px 2px 5px #999;
    /*overflow: auto;*/
}

.ReportContainerEmpty {
    width: calc(100% - 30px);
}