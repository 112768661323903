#WindowContentResetPassword #ContentResetPassword {
    width: 100%;
    height: calc(100% - 85px);
    overflow: hidden;
    overflow-y: auto;
    padding: 10px 10px;
}

#WindowContentResetPassword #ContentResetPassword .text {
    padding: 5px 15px;
    color: #666;
    text-align: justify;
}

.labelFlotante {
    -webkit-transform: translate3d(0,-104%,0);
    transform: translate3d(0,-104%,0);
    font-size: 12px;
    padding-left: 0;
    margin-left: 10px;
    color: #aaa;
}

.formContentField {
    width: 100%;
    margin-top: 24px;
    position: relative;
    display: inline-block;
    vertical-align: top;
}

#ContentResetPassword .divBtnReset {
    float: left;
    width: 100% !important;
    padding: 2px;
    vertical-align: top;
    background-color: #FFF;
    cursor: auto;
}

.divBtnReset {
	margin-top: 15px;
    color: #fff;
}

#ContentResetPassword .divBtnReset > button {
    float: right;
    width: 50%;
    cursor: pointer;
    font-size: 11px;    
    color: #FFF;
    font-size: 14px;
    border-radius: 5px;
}

.divBtnReset > button {
    width: 100%;
    background-color: rgba(0,0,0,0);
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: center top;
    min-height: 40px;
    min-width: 40px;
    cursor: pointer;
    padding: 0px 0 0 0;
    font-size: 11px;
    border: 1px solid transparent;
    text-decoration: none;
    outline: none;
}

.formContentField .labelFlotante {
    position: absolute;
    top: 1px;
    left: -2px;
    width: calc(100% - 50px);
    text-align: left;
    line-height: 30px;
    height: 22px;
    display: inline-block;    
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
    -webkit-transition: all 200ms;
    transition: all 200ms;
    padding-left: 0;
}

.formContentField label {
    width: 100%;
    max-width: calc(100% - 25px);
    display: inline-block;
    height: 18px;
    line-height: 30px;
    padding-left: 0;
    background-color: #fff;   
    margin-left: 25px;
}

.withLabel{
    width: 100%;
    height: 30px;
    line-height: 16px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: #333;
    background-color: #fff;   
    border: 1px solid #c9c9c9;
    outline: none;
    padding: 0 10px 0 10px;
    font-size: 14px;
    box-shadow: none;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
}

.formContentField {
    width: 100%;
    margin-top: 24px;
    position: relative;
    display: inline-block;
    vertical-align: top;
}