.fc .fc-toolbar-title {
    font-size: 14px !important;
    font-weight:bold;
    margin: 0;
}

.fc-day-today
{	
    background-color:inherit !important;
}

.fc-day-today .fc-daygrid-day-number
{	
    font-weight: bolder !important;
    font-size: 15px !important;
}

.fc-daygrid-event  div
{
	height: auto;
	white-space: normal;
	font-weight: bold;
	font-size:10px !important;
	overflow:ellipsis !important;
}

.fc-month-button {
	z-index: auto;
}

.fc-popover{
	background-color: white !important; 
}