.titulo{
	font-weight:bold;
	font-size: 15px;
}

.richTextEditor{
	min-height: 80px;
}

@media only screen and (max-width: 480px) {
	.espacioCopyright{
		margin-bottom: 35px;
	}
}

@media only screen and (max-width: 800px) {
    /* Force table to not be like tables anymore */
    #no-more-tables table,
    #no-more-tables thead,
    #no-more-tables tbody,
    #no-more-tables th,
    #no-more-tables td,
    #no-more-tables tr {
    	display: block;
	}
         
	/* Hide table headers (but not display: none;, for accessibility) */
	#no-more-tables thead tr {
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
	         
	#no-more-tables tr { border: 1px solid #ccc; }
	          
	#no-more-tables td {
		/* Behave like a "row" */
		border: none;
		border-bottom: 1px solid #eee;
		position: relative;
		padding-left: 50%;
		white-space: normal;
		text-align:left;
		/*height:25px;*/
		padding-top:0px !important;
		padding-bottom:0px !important;
	}
	 
	#no-more-tables td:before {
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		/*top: 6px;*/
		left: 6px;
		width: 45%;
		padding-right: 10px;
		white-space: nowrap;
		text-align:left;
		font-weight: bold;
	}
	 
	/*
	Label the data
	*/
	#no-more-tables td:before { content: attr(data-title); }

	.filaBotones{
		height:35px !important;
	}	

	#divBotonEditar {
    	margin-right: 2px !important;
	}
	.filtroSearch{
		float:left;		
	}
	.labelSearch{
		width:100px;
		font-weight: bold;	
		font-size:13px;		
	}
	.labelSelect{
		width:100px;		
	}
	.filtrosDatagrid{		
		padding-bottom: 4px;		
	}
}

.filaBotones{
	width:180px;
}

.container{
    margin-left: unset !important;
}

@media only screen and (min-width: 800px) {
	.tableDataGrid td,th{
		padding:0px 5px 0px 0px !important;		
	}
	.filtrosDatagrid{
		padding-left: 7px;
		padding-bottom: 4px;		
	}
	.filtroSearch{
		float:right;
		padding-left: 7px;
	}
	.labelSearch{
		width:60px;	
		font-weight: bold;
		font-size:13px;	
	}
	.labelSelect{
		width:60px;		
	}
}

#iconColumna{
	margin-top:-5px;
}

.pie_grilla{
	margin-bottom:35px;
}

@media only screen and (max-width: 480px){
    .pie_grilla {
        margin-bottom:70px;
    }
}