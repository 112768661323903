/**
* activos.css
*
* Contiene los estilos del modulo activos 
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

@media only screen and (min-width: 1115px){
    .EstiloIconosDashboardActivos li {
        margin: 7px;       
    }
}

@media only screen and (max-width: 1114px) and (min-width: 1025px){
    .EstiloIconosDashboardActivos li {
        margin: 7px;        
    }
}

@media only screen and (max-width: 1024px){
    .EstiloIconosDashboardActivos li {
        margin: 7px;
    }
}

@media only screen and (max-width: 480px){
    .EstiloIconosDashboardActivos li {
        margin: 7px;
        width:45% !important;
    }
}

@media only screen and (max-width: 320px){
    .EstiloIconosDashboardActivos li {        
        width:90% !important;
        margin: 7px;
    }
}

@media only screen and (min-width: 480px) and (max-width:600px){
    .EstiloIconosDashboardActivos li {        
        width:30% !important;
    }
}

@media only screen and (min-width: 600px) and (max-width:770px){
    .EstiloIconosDashboardActivos li {        
        width:22% !important;
    }
}

@media only screen and (min-width: 771px) and (max-width:1023px){
    .EstiloIconosDashboardActivos li {        
        width:18% !important;
    }
}

@media only screen and (min-width: 1024px) and (max-width:1223px){
    .EstiloIconosDashboardActivos li {        
        width:18% !important;
    }
}

@media only screen and (min-width: 1224px) {
    .EstiloIconosDashboardActivos li {        
        width:15% !important;
    }
}

.EstiloIconosDashboardActivos li {
    float: left;
    width: 180px;
    height: 160px;    
    padding: 5px;
    box-shadow: 2px 2px 10px -3px rgba(0,0,0,0.5);
}

.EstiloIconosDashboardActivos .tituloActivos {
    float: left;
    width: 100%;
    height: 25px;
    font-size: 11px;
    font-weight: bold;
    padding: 5px 0 0 0px;
}

.EstiloIconosDashboardActivos .titulo {
    float: left;
    width: 100%;
    height: 25px;
    font-size: 18px;
    font-weight: bold;
    padding: 5px 0 0 20px;
}

.EstiloIconosDashboardActivos li .data {
    width: 100%;
    height :60px;
    text-align: center;
    color: #333;
}

.EstiloIconosDashboardActivos li .icono {
    width: 48px;
    height: 48px;
    margin: 10px auto;
    text-align: left;
    background-size: cover;
    background-repeat: no-repeat;
}

.EstiloIconosDashboardActivos li div .material-icons {
    color: #2a3f54 !important;
    font-size: 50px !important;
    margin: -15px 0 0 -5px !important;
}

#dashIcon {
    float: left;
    left: -40px;
    top: 20px;
}

.divEstados{
    width:100px;
    height:60px;
    top: -35px;    
    left: 10px;
    position: relative;    
}

.filaEstados{
    font-size:10px;
    /*font-weight: bold;*/
}

#fichaTableXlsButton{
    color:white;
}