#uploaderImageUser {
    width: 100%;
    height: 100px;
    text-align: center;
    overflow: hidden;
}

#uploaderImageUser .divImageUploader {
    width: 100%;
    height: 100%;
    border-radius: 40px;
    margin: 0 auto;
}

#ImageUploader{
    width: 140px;
    height: 140px;
    border-radius: 40px;
}

#uploaderImageUser #inputUploader{
    border: none;
    box-shadow: none;
    background-color: #FFF;
    color: #FFF;
    opacity: 0;
    width: 100%;
    height: 140px;
    position: relative;
    top: -140px;
    left: 0;
    cursor: pointer;
}