/**
* desktop.css
*
* Contiene los estilos del escritorio* 
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/


/******************************MEDIA QUERYS********************************/

/**************************menu de navegacion*****************************/

@media only screen and (min-width: 1115px){
    .menuNavegacion {
        width: 290px;
        height: 100%;
        position: absolute; 
        top: 0;
        left: 0;
        z-index: 0;
    }
}

@media only screen and (min-width: 1115px){
    .navigationTrigger:checked ~ .menuNavegacion {
        width: 90px;
    }
}


@media only screen and (max-width: 1114px) and (min-width: 1025px){
    .menuNavegacion {
        width: 90px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;   
    }    
}

@media only screen and (max-width: 1114px) and (min-width: 1025px){    
    .navigationTrigger:checked ~ #pestanas li {
        width: 100%;
        height: 43px !important;
    }
}

@media only screen and (max-width: 1114px) and (min-width: 1025px){    
    .navigationTrigger:not(checked) ~ #pestanas li {
        width: 100%;
        height: 43px !important;
    }
}

@media only screen and (max-width: 1114px) and (min-width: 1025px){
    .navigationTrigger:checked ~ .menuNavegacion {
        width: 290px;
        box-shadow: 0 0 5px 5px rgba(0,0,0,0.5);
    }
}

@media only screen and (max-width: 1114px) and (min-width: 1025px){
    .navigationTrigger + label, .menuNavegacion {
        transition: width .1s;
    }
}

@media only screen and (max-width: 1024px){
    .menuNavegacion {
        width: 290px;
        height: 100%;
        position: absolute;
        top: 0;
        left: -290px;
        z-index: 1;
    }
}

@media only screen and (max-width: 1024px){
    .navigationTrigger:checked ~ .menuNavegacion {
        left: 0;
    }
}

@media only screen and (max-width: 1024px){
    .navigationTrigger + label, .menuNavegacion {
        transition: left .3s;
    }
}

/*****************************titulos del los menus**************************/

@media only screen and (min-width: 1115px){
    .navigationTrigger:checked ~ #pestanas .LiTitle {
        width: 100%;
        font-size: 10px;
        margin: 0;
        text-align: center;
        height:12px;
    }
}

@media only screen and (min-width: 1115px){
    .navigationTrigger:not(checked) ~ #pestanas .LiTitle {
        width: calc(100% - 80px);
        font-size: 16px;        
        text-align: left;        
    } 
}

@media only screen and (max-width: 1114px) and (min-width: 1025px){
   .navigationTrigger:not(checked) ~ #pestanas .LiTitle {
        width: 100%;
        font-size: 10px;
        margin: 0;
        text-align: center;  
        height:12px;      
    } 
}

@media only screen and (max-width: 1114px) and (min-width: 1025px){
    .navigationTrigger:checked ~ #pestanas .LiTitle {
        width: calc(100% - 80px);
        font-size: 16px;        
        text-align: left;   
        height:19px;     
    }
}

@media only screen and (max-width: 1114px) and (min-width: 1025px){
   .navigationTrigger:not(checked) ~ #pestanas .LiTitle {
        width: 100%;
        font-size: 10px;
        margin: 0;
        text-align: center;
    } 
}

/*************************Descripciones de los menus*************************/

@media only screen and (min-width: 1115px){
    .navigationTrigger:not(checked) ~ #pestanas .LiDescrip {
        width: calc(100% - 80px);
        height: 20px;
        font-size: 11px;
    }
}

@media only screen and (min-width: 1115px){
   .navigationTrigger:checked ~ #pestanas .LiDescrip {
        width: 0;
        height: 0;
        font-size: 0;
    } 
}

@media only screen and (max-width: 1114px) and (min-width: 1025px){
    .navigationTrigger:checked ~ #pestanas .LiDescrip {
        width: calc(100% - 80px);
        height: 20px;
        font-size: 11px;
    }
}

@media only screen and (max-width: 1114px) and (min-width: 1025px){
    .navigationTrigger:not(checked) ~ #pestanas .LiDescrip {
        width: 0;
        height: 0;
        font-size: 0;
    }
}


@media only screen and (max-width: 1114px) and (min-width: 1025px){
    .navigationTrigger:not(checked) ~ #pestanas .LiDescrip {
        width: 0;
        height: 0;
        font-size: 0; 
    }
}

/*****************************boton disparador*******************************/

input[type=checkbox] {
    box-shadow: none;
    width: 16px;
}

@media only screen and (min-width: 1115px){
    .navigationTrigger:checked + label {
        left: 95px;
    }
}

@media only screen and (min-width: 1115px){
    .navigationTrigger + label > i {
        color: #fff;
        font-size: 24px;
        top: 0;
    }    
}

@media only screen and (min-width: 1115px){
    label[for="navigationTrigger"] {
        position: fixed;
        left: 255px;
        top: 6px;
        z-index: 2;
        height: 0;
        width: 0;
        cursor: pointer;
        background-size: contain;
    }
}

@media only screen and (min-width: 1115px){
    .navigationTrigger {
        position: absolute;
        top: 0;
        left: 0;
        clip: rect(0,0,0,0);
    }
}

@media only screen and (max-width: 1114px) and (min-width: 1025px){
    .navigationTrigger:checked + label {
        left: 255px;
    }
}

@media only screen and (max-width: 1114px) and (min-width: 1025px){
    .navigationTrigger + label > i {
        color: #fff;
        font-size: 24px;
        top: 0;
    }
}

@media only screen and (max-width: 1114px) and (min-width: 1025px){
    label[for="navigationTrigger"] {
        position: fixed;
        left: 95px;
        top: 6px;
        z-index: 2;
        height: 30px;
        width: 30px;
        cursor: pointer;
        background-size: contain;
    }
}


@media only screen and (max-width: 1024px){
    .navigationTrigger:checked + label {
        left: 255px;
    }
}

@media only screen and (max-width: 1024px){
    .navigationTrigger + label > i {
        color: #fff;
        font-size: 24px;
        top: 0;
    }
}

@media only screen and (max-width: 1024px){
    label[for="navigationTrigger"] {
        position: fixed;
        left: 5px;
        top: 18px;
        z-index: 2;
        height: 30px;
        width: 30px;
        cursor: pointer;
        background-size: contain;
    }
}

@media only screen and (max-width: 1024px){
    .navigationTrigger {
        position: absolute;
        top: 0;
        left: 0;
        clip: rect(0,0,0,0);
    }
}

/*****************************contenedor derecho*****************************/

.ContenedorDerechoEscritorio {
    float: left;
    width: 100%;
    height: 100%;
    float: left;
    padding: 0;
    margin: 0;
    background-color: #2a3f54;
}

@media only screen and (min-width: 1115px){
    .ContenedorDerechoEscritorio {
        width: 100%;
        height: 100%;
        max-width: calc(100% - 290px);
        position: absolute;
        top: 0;
        left: 290px;
    }
}

@media only screen and (min-width: 1115px){
    .navigationTrigger:checked ~ .ContenedorDerechoEscritorio {
        max-width: calc(100% - 90px);
        left: 90px;
    }
}

@media only screen and (max-width: 1114px) and (min-width: 1025px){
    .ContenedorDerechoEscritorio {
        min-height: 100%;
        min-width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
}

@media only screen and (max-width: 1024px){
    .navigationTrigger:checked ~ .ContenedorDerechoEscritorio { 
        /*transform: translate3d(150px,0,0) rotateY(-30deg);*/
        box-shadow: 0 0 10px -1px rgba(0,0,0,0.5);
    } 
}

@media only screen and (max-width: 1024px){
    .ContenedorDerechoEscritorio {
        min-height: 100%;
        min-width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        transition-duration: .3s;
        transform-origin: 100% center 0;
    } 
}

/************************cabecera del escritorio***************************/

@media only screen and (min-width: 1115px){
    .cabeceraEscritorio {
        min-height: 60px;
        max-height: 60px;
        width: 100%;
        position: relative;
        top: 0;
        left: 0;
    }
    .divCopyright {
        max-width: calc(100% - 290px);
        font-size: 10px;
    }
    .linkPage{
        font-size: 10px;
    }
}

@media only screen and (max-width: 1114px) and (min-width: 1025px){
    .cabeceraEscritorio {
        min-height: 60px;
        max-height: 60px;
        max-width: calc(100% - 90px);
        width: 100%;
        position: relative;
        top: 0;
        left: 90px;
    }
    .divCopyright {
        font-size: 10px;
    }
    .linkPage{
        font-size: 10px;
    }
}

@media only screen and (max-width: 1024px){
   .cabeceraEscritorio {
        min-height: 60px;
        min-width: 100%;
        position: relative;
        top: 0;
        left: 0;
    } 
    .divCopyright {
        font-size: 9px;
    }
    .linkPage{
        font-size: 9px;
    }
}

/*******************************logo de la cabecera*******************************/

@media only screen and (min-width: 1115px){
    .navigationTrigger:checked ~ #pestanas .LogoCabecera {
        background-image: url(./../../images/logo_login.min.png?v1.0);
    }
}

@media only screen and (min-width: 1115px){
   .navigationTrigger:not(checked) ~ #pestanas .LogoCabecera {
        background-image: url(./../../images/logo_login.png?v1.0);
        max-height: 90px;
    } 
}

@media only screen and (min-width: 1115px){
   .navigationTrigger:checked ~ #pestanas .LogoCabecera {
        width: 70px;
        max-height: 58px;
    } 
}

@media only screen and (max-width: 1114px) and (min-width: 1025px){
    .navigationTrigger:checked ~ #pestanas .LogoCabecera {
        width: 240px;
        max-height: 90px;
    }
}

@media only screen and (max-width: 1114px) and (min-width: 1025px){
    .navigationTrigger:checked ~ #pestanas .LogoCabecera {
        background-image: url(./../../images/logo_login.png?v1.0);
    }
}

@media only screen and (max-width: 1114px) and (min-width: 1025px){
    .navigationTrigger:not(checked) ~ #pestanas .LogoCabecera {
        background-image: url(./../../images/logo_login.min.png?v1.0);
    }
}

@media only screen and (max-width: 1114px) and (min-width: 1025px){
    .navigationTrigger:not(checked) ~ #pestanas .LogoCabecera {
        width: 90px;
        max-height: 58px;
    }
}

@media only screen and (max-width: 1024px){
    .navigationTrigger:checked ~ #pestanas .LogoCabecera {        
        max-height: 90px;
    }
}

@media only screen and (max-width: 1024px){
    .navigationTrigger:not(checked) ~ #pestanas .LogoCabecera {        
        max-height: 90px;
        width: 240px;
    }
}

/****************************contenedor del escritorio**************************/

@media only screen and (min-width: 1115px){
    .contenidoEscritorio {
        min-height: calc(100% - 60px);
        max-height: calc(100% - 60px);
        width: 100%;
        position: relative;
        top: 0;
        left: 0;
    }
}

@media only screen and (max-width: 1114px) and (min-width: 1025px){
    .contenidoEscritorio {
        min-height: calc(100% - 60px);
        max-height: calc(100% - 60px);
        max-width: calc(100% - 90px);
        width: 100%;
        position: relative;
        top: 0;
        left: 90px;
    }
}

@media only screen and (max-width: 1024px){
    .contenidoEscritorio {
        min-height: calc(100% - 60px);
        min-width: 100%;
        position: relative;
        top: 0;
        left: 0;
    }
}

/********************************ESTILOS DEL ESCRITORIO********************************/

* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
    box-sizing: border-box;
    font-family: Verdana,Tahoma,sans-serif;
    font-size: 14px;
}

.cabeceraDesk {
    float: left;
    font-size: 11px;
    width: 100%;
    height: 60px;
    float: left;
    padding: 0;
    margin: 0;
    background-color: #2a3f54;
    min-height: 60px;
    max-height: 60px;
    max-width: calc(100% - 290px); 
    position: absolute;
    top: 0;
    left: 290px;
}




#lista{
    margin-top:20px;
}

#pestanas {
    overflow: hidden;
    overflow-y: auto;
    background-color: #2a3f54;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#pestanas .LiTitle {
    width: calc(100% - 80px);
    height: 19px;
    float: left;
    font-size: 16px;
    font-weight: 300;   
    color: #fff;
}

#pestanas .LiDescrip {
    width: calc(100% - 80px);
    height: 20px;
    float: left;
    font-size: 11px;
    font-weight: 200;
    color: #bbb;
}

#contenidopestanas {
    width: 100%;
    height: calc(100% - 60px);
    max-height: calc(100% - 60px);
    float: left;
    background: #fff;
    margin: 0;
    overflow-x: hidden;
}

#contenidopestanas > div >div {
    width: 100%;
    height: calc(100%);
    float: left;
    background: #fff;
    margin: 0;
}

.NombreUsuario {
    float: left;
    width: 195px;
    margin: 8px 5px 0 5px;
    font-size: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #fff;
}

.OptionUsuario {
    float: left;
    width: 24px;
    margin: 12px 0px 0 0px;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #fff;
}

.ContenidoUser {
    width: 300px;
    height: 60px;
    float: right;
    padding: 0 10px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.ContenidoUser .FotoUsuario {
    margin-top: 5px;
    float: left;
    width: 50px;
    height: 50px;
}

.ContenidoUser .FotoUsuario img {
    width: 48px;
    height: 48px;
    border-radius: 24px;
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
    position: relative;
    top: 5px;
    text-align: center;
}

#pestanas .LiIcon {
    width: 30px;
    height: 30px;
    margin: 0px 12px;
    margin-left: 30px;
    float: left;
}

#pestanas .LiIcon > i {
    font-size: 50px;
    top: 0;
}

#pestanas li {
    width: 100%;
    height: 43px;
}

.LogoCabecera {
    margin: 5px 0;
    width: 240px;  
    height:100%;  
    margin-left: 10px;
    background-repeat: no-repeat;
}

.LogoCabecera {
    background-image: url(./../../images/logo_login.png?v1.0);
}

.LogoCabecera2 {
    background-image: url(./../../images/logo_login1.png?v1.0);    
}

body, html {
    margin: 0;
    height: 100%;
    padding: 0;
    overflow: visible;
    font-size: 12px;
}

#root {
    width : 100%;
    height : 100%; 
}

.App {
    width : 100%;
    height : 100%;    
}

.LogoFondo {
    width : 100%;
    height : 100%;    
}

.LogoFondo > img{
    vertical-align: middle;      
}

.WelcomeFondo{
    background-position: center; 
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
} 

.ContenedorDataGrid{    
    height: calc(100% - 62px);    
} 

#contentImageUser {
    width: 100%;
    height: 100px;
    text-align: center;
}

#contentImageUser .divImageUser {
    width: 80px;
    height: 80px;
    border-radius: 40px;
    margin: 0 auto;
}

#ImageUser{
    width: 80px;
    height: 80px;
    border-radius: 40px;
}

#contentImageUser #uploadedImageUser {
    border: none;
    box-shadow: none;
    background-color: #FFF;
    color: #FFF;
    opacity: 0;
    width: 80px;
    height: 80px;
    position: relative;
    top: -80px;
    left: 0;
    cursor: pointer;
}

#pestanas::-webkit-scrollbar {
    width: 0;
    background-color: #2a3f54;
}

.alertify .ajs-body .ajs-content .ajs-input {
    display: block;
    width: 100%;
    padding: 8px;
    margin: 4px;
    border-radius: 2px;
    border: 1px solid #CCC;
}

.divCopyright{
    width: 250px;
    text-align: right;
    right:250px;
    position: fixed;
    margin-right: 20px;
    bottom: 0px;       
    font-weight: bold;
    line-height: 12px;
}

@media only screen and (max-width: 480px){
    .divCopyright {
        right:0px;
        bottom:35px;
    }
}

