/**
* controlpanel.css
*
* Contiene los estilos del modulo panel de control* 
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

@media only screen and (min-width: 1115px){
    .EstiloIconosMenuDashboard li {
        margin: 7px;       
    }
}

@media only screen and (max-width: 1114px) and (min-width: 1025px){
    .EstiloIconosMenuDashboard li {
        margin: 7px;        
    }
}

@media only screen and (max-width: 1024px){
    .EstiloIconosMenuDashboard li {
        margin: 7px;
    }
}

@media only screen and (max-width: 480px){
    .EstiloIconosMenuDashboard li {
        margin: 15px;
        width:40% !important;
    }
}

@media only screen and (max-width: 320px){
    .EstiloIconosMenuDashboard li {        
        width:90% !important;
    }
}

@media only screen and (min-width: 480px) and (max-width:600px){
    .EstiloIconosMenuDashboard li {        
        width:30% !important;
    }
}

@media only screen and (min-width: 600px) and (max-width:770px){
    .EstiloIconosMenuDashboard li {        
        width:22% !important;
    }
}

@media only screen and (min-width: 771px) and (max-width:1023px){
    .EstiloIconosMenuDashboard li {        
        width:18% !important;
    }
}

@media only screen and (min-width: 1024px) and (max-width:1223px){
    .EstiloIconosMenuDashboard li {        
        width:18% !important;
    }
}

@media only screen and (min-width: 1224px) {
    .EstiloIconosMenuDashboard li {        
        width:15% !important;
    }
}

.EstiloIconosMenuDashboard li {
    float: left;
    width: 150px;
    height: 150px;   
    padding: 15px;
    box-shadow: 2px 2px 10px -3px rgba(0,0,0,0.5);
}

.EstiloIconosMenuDashboard .titulo {
    float: left;
    width: 100%;
    height: 25px;
    font-size: 18px;
    font-weight: bold;
    padding: 5px 0 0 20px;
}

.EstiloIconosMenuDashboard li .data {
    width: 100%;
    height :60px;
    text-align: center;
    color: #333;
}

.EstiloIconosMenuDashboard li .icono {
    width: 48px;
    height: 48px;
    margin: 10px auto;
    margin-top: 20px;
    background-size: cover;
    background-repeat: no-repeat;
}

.EstiloIconosMenuDashboard li div .material-icons {
    color: #2a3f54 !important;
    font-size: 50px !important;
    margin: -15px 0 0 -5px !important;
}