.tbarContainer {
    padding: 0 10px 0 10px;
    width: 100%;
    height:60px !important;
    margin: 0;
    overflow: hidden;
    display: table;
    position: fixed;    
}

.tbarButton {
    padding: 2px;
    vertical-align: top;
    background-color: rgba(0,0,0,0);
    min-height: 50px;
    min-width: 50px;    
    text-align: center; 
    float:left;   
}

.tbarButton button {
    padding: 0;
}

.tbarButton button {
    width: 100%;
    color:#fff;
    background-color: rgba(0,0,0,0);
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: center top;
    min-height: 20px;
    min-width: 40px;
    cursor: pointer;
    padding: 0px 0 0 0;
    font-size: 11px;
    border: 1px solid transparent;
    text-decoration: none;
    outline: none;    
}

.tbarSeparator {
    border-color: #fff;
    width: 1px;
    border-right: 1px solid;
    opacity: .2;
    padding: 2px;
    float:left;
}