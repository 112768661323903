.optionTab {
    background-color: #2a80b9;
    color: #fff;
    border-bottom: 2px solid transparent;
    float: left;
    cursor: pointer;
    height: calc(100% - 5px);
    margin: 5px 5px 0 0;
    min-width: 50px;
    font-size: 11px;
    padding: 8px 10px 0 10px;
}

.optionTab[data-selected=true] {
    color: #fff;
    border-top: 2px solid #fff;
    -webkit-box-shadow: 0 2px 8px -1px rgba(51,51,51,0.5);
    -moz-box-shadow: 0 2px 8px -1px rgba(51,51,51,0.5);
    box-shadow: 0 2px 8px -1px rgba(51,51,51,0.5);
}

.ContentTabs{
    background-color: #2a80b9 !important;
}